import React from 'react';
import './ErrorScreen.css';
import Layout from "antd/lib/layout/layout";
import {Spin, Typography} from "antd";
const { Text, Link } = Typography;

type Props = {
  errorCode: string
}

function ErrorScreen(props: Props) {
  // errorCode
  return (
    <Layout style={{height: "100vh", justifyContent: 'center', alignItems:'center'}} className={"Layout"}>
      <Text> Error: {props.errorCode} </Text>
    </Layout>
  );
}

export default ErrorScreen;