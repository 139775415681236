import React from 'react';
import './LoadingScreen.css';
import Layout from "antd/lib/layout/layout";
import {Spin} from "antd";

function LoadingScreen() {
  return (
    <Layout style={{height: "100vh", justifyContent: 'center', alignItems:'center'}} className={"Layout"}>
        <Spin size={"large"} style={{alignSelf:'center'}} />
    </Layout>
  );
}

export default LoadingScreen;