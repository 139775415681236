import React from 'react';
import './CustomHeader.css';
import {Button, Col, Popover, Row, Typography} from "antd";
import {InfoCircleOutlined, LoadingOutlined, SaveOutlined} from "@ant-design/icons";

import {Script} from "../model/Script";
import CodeChangeState from "../model/internal/EditingCode";

const { Text} = Typography;

const tooltipText = "Shortcut to save the script, press: ctrl + alt";

type Props = {
  script: Script,
  codeState: CodeChangeState,
  isSavingOnBackend: boolean,
  pressedToSave: () => void
}

const headerColStyle: React.CSSProperties = {
  textAlign: "right",
  paddingRight: 16,
  verticalAlign: "middle"
};

function CustomHeader(props: Props) {
  const codeState = props.codeState;

  let loaderStyle: React.CSSProperties = {};
  if (! props.isSavingOnBackend) {
    loaderStyle.display = "none";
  }

  return (
    <Row justify={"center"} align={"middle"}>
      <Col span={20}>
        <Text className="Script-Name" >{props.script.name}</Text>
      </Col>
      <Col span={4} style={headerColStyle}>
        <LoadingOutlined className="Loader" style={loaderStyle} />
        <Button type="primary" shape="circle" disabled={codeState === CodeChangeState.NO_CHANGES} icon={<SaveOutlined />} onClick={props.pressedToSave} size="large" />
        <Popover content={tooltipText} title="">
          <InfoCircleOutlined className="Helper" />
        </Popover>
      </Col>
    </Row>
  )
}

// export default {CustomHeader, CodeChangeState};
export default CustomHeader;