import React, {useState} from 'react';
import './App.css';
import LoadedEditor from "./loaded-editor/LoadedEditor";
import {ConfigProvider, theme} from "antd";
import LoadingScreen from "./loading-screen/LoadingScreen";
import {useQuery, UseQueryResult} from "react-query";
import ErrorScreen from "./error-screen/ErrorScreen";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  gql,
  ApolloError,
  NormalizedCache,
  NormalizedCacheObject, ApolloQueryResult
} from '@apollo/client';
import {Script} from "./model/Script";

const backendAPI = "https://backend.wisel.mikha-el.com/graphql";
let client: ApolloClient<NormalizedCacheObject> = buildClient(undefined);

type AuthInfo = {
  authToken: string,
  scriptID: number,
};

enum PageState {
  Unknown,
  NoAuth,
  AuthInfoProvided,
  AuthInfoPendingValidation,
  AuthInfoInvalid,
  AuthInfoValid,
}

function App() {
  const [auth, setAuth] = useState<AuthInfo | null>(null);
  const [pageState, setPageState] = useState(PageState.Unknown);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [script, setScript] = useState<Script | null>(null);

  // Step 1
  if (pageState === PageState.Unknown) {
    let authInfo = retrieveAuth();
    setPageState(authInfo === null ? PageState.NoAuth : PageState.AuthInfoProvided);
    setAuth(authInfo);
  }

  // Step 2
  if (pageState === PageState.AuthInfoProvided) {
    client = buildClient(auth?.authToken);

    setPageState(PageState.AuthInfoPendingValidation);
    findScript(auth?.scriptID ?? -1).then((result) => {
      let script = getScriptFromResult(result);
      setScript(script);
      setPageState(PageState.AuthInfoValid);

    }).catch((reason: ApolloError) => {
      console.error("Error on apollo query: " + JSON.stringify(reason));
      setPageState(PageState.AuthInfoInvalid);
      setErrorMessage(getErrorMessageFromError(reason));
    });
  }

  // Step 3
  if (pageState === PageState.AuthInfoPendingValidation) {
    console.log("Waiting for loading")
  }

  let rootComponent;
  switch (pageState) {
    case PageState.NoAuth:
      rootComponent = <ErrorScreen errorCode={"No auth provided. Try starting again (from the mobile/web app)"}/>;
      break;

    case PageState.AuthInfoPendingValidation:
      rootComponent = <LoadingScreen/>;
      break

    case PageState.AuthInfoValid:
      rootComponent = <LoadedEditor apolloClient={client} script={script!}/>;
      break;

    case PageState.AuthInfoInvalid:
      rootComponent = <ErrorScreen errorCode={errorMessage}/>;
      break;

    case PageState.AuthInfoProvided:
    case PageState.Unknown:
    default:
      rootComponent = <ErrorScreen errorCode={`How is possible to be in this state [${pageState}]? This must be an error and must be fixed`}/>;
  }

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
      }}>
      {rootComponent}
    </ConfigProvider>
  )
}

function retrieveAuth(): AuthInfo | null {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get("tk");
  const scriptID = urlParams.get("scriptID");

  window.history.replaceState({}, document.title, "/");
  if (token != null && token.trim().length > 0 && scriptID != null && scriptID.trim().length > 0) {
    return {
      authToken: token,
      scriptID: parseInt(scriptID)
    }
  } else return null;
}

export default App;

function buildClient(authHeader: string | undefined): ApolloClient<NormalizedCacheObject> {
  let headers: Record<string, string> | undefined;
  if (authHeader !== null) {
    headers = {
      Authorization: `user ${authHeader}`
    }
  }

  return new ApolloClient({
    uri: backendAPI,
    cache: new InMemoryCache(),
    headers: headers,
  });
}

async function findScript(scriptID: number) {
  return client
    .query({
      query: FindScriptQuery(scriptID),
    });
}

function FindScriptQuery(scriptID: number) {
  return gql`
query findMyScript {
  findMyScript(scriptID: ${scriptID}) {
    commonResponse {
      statusCode, description, additionalPayload, isError
    }
    script {
      id, name, script
    }
  }
}`;
}

function getErrorMessageFromError(result: ApolloError): string {
  try {
    let error: any = result.graphQLErrors[0].extensions;
    return error.commonResponse.description;
  } catch (error) {
    return "Not possible to find error: " + JSON.stringify(error);
  }
}

function getScriptFromResult(result: ApolloQueryResult<any>): Script | null {
  try {
    let data = result.data;
    let script = data.findMyScript.script;
    return script;
  } catch (error) {
    return null;
  }
}