import React, {useMemo, useState} from 'react';
import './LoadedEditor.css';
import {Col, Layout, notification, Row, Select, Typography} from "antd";
import {Content, Footer, Header} from "antd/lib/layout/layout";
import CustomHeader from "../header/CustomHeader";
import {Editor, Monaco} from "@monaco-editor/react";
import {ApolloClient, ApolloError, gql, NormalizedCacheObject} from "@apollo/client";
import {Script} from "../model/Script";
import {editor} from 'monaco-editor';
import {NotificationPlacement} from "antd/lib/notification/interface";
import CodeChangeState from "../model/internal/EditingCode";
import {sleep} from "react-query/types/core/utils";

const { Text, Link } = Typography;

const headerStyle: React.CSSProperties = {
  textAlign: 'center',
  margin: 0,
  padding: 0
};

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

type Props = {
  apolloClient: ApolloClient<NormalizedCacheObject>,
  script: Script,
}

const Context = React.createContext({ name: 'Default' });

function LoadedEditor(props: Props) {
  const [language, setLanguage] = useState("powershell");
  const [codeState, setCodeState] = useState<CodeChangeState>(CodeChangeState.NO_CHANGES);
  const [editor, setEditor] = useState<editor.IStandaloneCodeEditor | null>(null);
  const [api, contextHolder] = notification.useNotification();
  const [isSavingOnBackend, setIsSavingOnBackend] = useState<boolean>(false);
  const [shortcutSavePressed, setShortcutSavePressed] = useState<boolean>(false);

  if (apolloClient === null) {
    apolloClient = props.apolloClient;
  }

  function handleLanguageChange(newLanguage: string, e: any) {
    setLanguage(newLanguage);
  }

  function editorDidMount(editor: editor.IStandaloneCodeEditor, monaco: Monaco) {
    editor.focus();
    setEditor(editor);

    editor.onKeyDown(function (e) {
      if (e.ctrlKey && e.altKey) {
        setShortcutSavePressed(true);
      }
    });
  }

  function handleCodeChanged(value: string | undefined, ev: editor.IModelContentChangedEvent) {
    if (codeState === CodeChangeState.NO_CHANGES) setCodeState(CodeChangeState.HAS_CHANGES);
  }

  function shouldSaveScript() {
    if (codeState === CodeChangeState.HAS_CHANGES) {
      setCodeState(CodeChangeState.NO_CHANGES);
      setIsSavingOnBackend(true);

      updateScript(props.script, editor?.getValue() ?? "no value")
        .then(value => {
          openNotification("topRight",
            "Script saved 😎",
            null);

          setIsSavingOnBackend(false);

        }).catch(reason => {
          console.error(`Error saving script: ${reason}`);

          openNotification("topRight",
            "Error saving script😟",
            getErrorMessageFromError(reason));
        })
    }

  }

  const openNotification = (placement: NotificationPlacement, message: string, description: string | null) => {
    api.info({
      message: message,
      description: <Context.Consumer>{() => description}</Context.Consumer>,
      placement,
    });
  };

  const contextValue = useMemo(() => ({ name: 'Ant Design' }), []);

  if (shortcutSavePressed) {
    setShortcutSavePressed(false);
    shouldSaveScript();
  }

  return (
      <Context.Provider value={contextValue}>
        {contextHolder}
        <Layout style={{height:"100vh"}}>
          <Header style={headerStyle}> <CustomHeader script={props.script} codeState={codeState} pressedToSave={shouldSaveScript} isSavingOnBackend={isSavingOnBackend} /> </Header>
          <Content >
            {/*https://github.com/microsoft/monaco-editor/tree/main/src/basic-languages*/}
            <Editor height="93.35vh" language={language} value={props.script.script} onMount={editorDidMount} onChange={handleCodeChanged}
                    theme="vs-dark"/>
          </Content>
          <Footer>
            <Row style={{float: "right", height: 16, marginRight: 4}}>
              <Col span={24}>
              <Text>Script language </Text>
              <Select
                defaultValue={language}
                style={{ width: 220 }}
                onChange={handleLanguageChange}
                options={[
                  { value: 'shell', label: 'shell' },
                  { value: 'powershell', label: 'powershell' },
                  { value: 'bat', label: 'bat' },
                ]}
              />
              </Col>
            </Row>

          </Footer>
        </Layout>
      </Context.Provider>
  );
}

async function updateScript(script: Script, newCode: string) {
  // await timeout(5000);
  return apolloClient!
    .mutate({
      mutation: UpdateScriptMutation(),
      variables: {
        scriptID: script.id,
        scriptName: script.name,
        script: newCode,
      }
    });
}

function timeout(delay: number) {
  return new Promise( res => setTimeout(res, delay) );
}

function UpdateScriptMutation() {
  return gql`
mutation updateRunnableScript($scriptID: Long!, $scriptName: String!, $script: String!) {
  updateRunnableScript(id: $scriptID, name: $scriptName, script: $script) {
    statusCode, description, additionalPayload, isError
  }
}`;
}

function getErrorMessageFromError(result: ApolloError): string {
  try {
    let error: any = result.graphQLErrors[0].extensions;
    return error.commonResponse.description;
  } catch (error) {
    return "Not possible to find error: " + JSON.stringify(error);
  }
}

export default LoadedEditor;